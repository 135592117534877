import React, { Suspense, lazy } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { APP_ROUTES } from './constants';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import Spinner from '../components/Spinner';

const LoadingSession = lazy(() => import('features/LoadingSession'));
const InvalidSession = lazy(() => import('features/InvalidSession'));
const NumberStatus = lazy(() => import('features/NumberStatus'));
const ConsentRelationship = lazy(() => import('features/ConsentRelationship'));
const AuthWrapper = lazy(() => import('features/auth/AuthWrapper'));
const ResetPassword = lazy(() => import('features/auth/ResetPassword'));

const AppRouter = () => {
    return (
        <Router>
            <NavigationBar />
            <Suspense
                fallback={
                    <Spinner
                        hAlign="center"
                        vAlign="center"
                        size="medium"
                        isLoading={true}
                        styles={{ height: '105vh' }}
                    />
                }
            >
                <Routes>
                    <Route path={APP_ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
                    <Route path={APP_ROUTES.SIGN_IN} element={<AuthWrapper />} />
                    <Route path={APP_ROUTES.VALIDATING} element={<LoadingSession />} />
                    <Route path={APP_ROUTES.INVALID_SESSION} element={<InvalidSession />} />
                    <Route path={APP_ROUTES.NUMBER_STATUS} element={<NumberStatus />} />
                    <Route
                        path={APP_ROUTES.CONSENT_RELATIONSHIP}
                        element={<ConsentRelationship />}
                    />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default AppRouter;
