import AppIcon from '../../assets/icons/App';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import React from 'react';
import { Flex } from '@fluentui/react-northstar';
import { useResponsive } from '../../hooks/useResponsive';
import UserMenu from '../UserMenu';

const NavigationBar = () => {
    const { isTabletOrMobile } = useResponsive();

    const appNameStyle = {
        lineHeight: '24px',
        fontSize: 18,
        fontWeight: 'bold' as const,
        height: 24,
        marginTop: 17,
    };

    return (
        <Flex
            className="fs-unmask"
            hAlign={isTabletOrMobile ? 'center' : 'start'}
            style={{
                width: '100%',
                minWidth: '320px',
                height: 60,
                backgroundColor: '#FFF',
                display: 'flex',
                flexDirection: 'row',
                borderBottomColor: '#EBEBEB',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
            }}
        >
            <AppIcon />
            <div style={appNameStyle}>
                <FormattedMessage {...messages.appName} />
            </div>
            <UserMenu styles={{ marginLeft: 'auto' }} />
        </Flex>
    );
};

export default NavigationBar;
