import React from 'react';
import './App.css';
import { IntlProvider } from 'react-intl';
import locales from './translations/locales';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Provider } from '@fluentui/react-northstar';
import { useTeams } from 'msteams-react-base-component';
import AppRouter from './routes';

function App() {
    const queryClient = new QueryClient();
    const locale = 'en'; //todo fix on adding more languages
    const messages = locales[locale];
    const [{ theme }] = useTeams();

    return (
        <IntlProvider locale={locale} messages={messages}>
            <QueryClientProvider client={queryClient}>
                <Provider theme={theme}>
                    <div className="App">
                        <AppRouter />
                    </div>
                </Provider>
            </QueryClientProvider>
        </IntlProvider>
    );
}

export default App;
