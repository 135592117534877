export const ERROR_MESSAGE_TYPE = {
    EXISTS: 'mainErrorAlertMessageExists',
    GENERIC: 'mainErrorAlertMessage',
    INVALID: 'mainErrorAlertMessageInvalid',
    CONSENT_ERROR: 'consentError',
    NUMBER_STATUS_GENERIC_ERROR: 'numberStatusErrorAlertMessage',
};

export const AUTH_STATE = {
    SIGN_IN: 'sign-in',
    NEW_PASSWORD: 'new-password',
    MFA_SETUP: 'mfa-setup',
    MFA_CODE: 'mfa-code',
    RESET_PASSWORD: 'reset-password',
};
