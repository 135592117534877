import { defineMessages } from 'react-intl';

const messages = defineMessages({
    resetPasswordTextItem: {
        id: 'user.menu.reset.password.text.item',
        defaultMessage: 'Reset password',
    },
    signOutTextItem: {
        id: 'user.menu.sign.out.text.item',
        defaultMessage: 'Sign out',
    },
});

export default messages as typeof messages;
