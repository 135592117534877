import { useMediaQuery } from 'react-responsive';

interface IResponsive {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isLargeDesktop: boolean;
    isTabletOrMobile: boolean;
}
export const useResponsive = (): IResponsive => {
    const isMobile = useMediaQuery({ maxDeviceWidth: 550 });
    const isTablet = useMediaQuery({ minDeviceWidth: 551, maxDeviceWidth: 1024 });
    const isDesktop = useMediaQuery({ minDeviceWidth: 1025, maxDeviceWidth: 1278 });
    const isLargeDesktop = useMediaQuery({ minDeviceWidth: 1279 });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
    return { isMobile, isTablet, isDesktop, isLargeDesktop, isTabletOrMobile };
};

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};
