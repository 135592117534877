import React from 'react';
import {
    Flex,
    Layout,
    PersonIcon,
    MenuIcon,
    Menu,
    LockIcon,
    Text,
    DoorArrowLeftIcon,
} from '@fluentui/react-northstar';
import { useLocalStorage } from '../../hooks/useLocalStorage';
// eslint-disable-next-line import/named
import { ComponentSlotStyle } from '@fluentui/styles';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes/constants';
import { useAuth } from '../../features/auth/hooks/useAuth';
import { useIntl } from 'react-intl';
import messages from './messages';

const UserMenu = ({ styles }: { styles?: ComponentSlotStyle<any, any> }) => {
    const [username, setUsername] = useLocalStorage(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_CLIENT_ID}.LastAuthUser`
    );
    const [invalidSession] = useLocalStorage('invalidSession');
    const history = useNavigate();
    const { signOut } = useAuth;
    const intl = useIntl();

    return (
        <Flex styles={styles}>
            {username && !invalidSession && (
                <Flex>
                    <Layout
                        // debug
                        start={<PersonIcon outline styles={{ marginRight: '8px' }} />}
                        main={<Text weight={'semibold'}>{username}</Text>}
                        end={
                            <Menu
                                defaultActiveIndex={0}
                                items={[
                                    {
                                        icon: <MenuIcon />,
                                        key: 'menuButton2',
                                        'aria-label': 'More options',
                                        indicator: false,
                                        menu: {
                                            items: [
                                                {
                                                    key: 'item-0',
                                                    content: intl.formatMessage(
                                                        messages.resetPasswordTextItem
                                                    ),
                                                    icon: <LockIcon />,
                                                    onClick: () => {
                                                        setUsername(undefined);
                                                        history(APP_ROUTES.RESET_PASSWORD);
                                                    },
                                                },
                                                {
                                                    key: 'item-1',
                                                    content: intl.formatMessage(
                                                        messages.signOutTextItem
                                                    ),
                                                    icon: <DoorArrowLeftIcon outline />,
                                                    onClick: () => {
                                                        setUsername(undefined);
                                                        signOut();
                                                        history(APP_ROUTES.SIGN_IN);
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                ]}
                                iconOnly
                                aria-label="User menu"
                            />
                        }
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default UserMenu;
