import { defineMessages } from 'react-intl';

const messages = defineMessages({
    appName: {
        id: 'general.appName',
        defaultMessage: 'Gamma',
    },
    helpSection: {
        id: 'general.navigation.help.section.text',
        defaultMessage: 'Help',
    },
    checkNumberStatus: {
        id: 'general.navigation.number.check.status.text',
        defaultMessage: 'Check number status',
    },
});

export default messages as typeof messages;
