import {
    AuthenticationDetails,
    // eslint-disable-next-line import/named
    ChallengeName,
    CognitoUser,
    CognitoUserPool,
} from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';
import { AUTH_STATE } from '../../../constants/constants';
import { publish } from '../../../utils/events';

const userPoolId = process.env.REACT_APP_USERPOOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;
const poolData = {
    UserPoolId: `${userPoolId}`,
    ClientId: `${clientId}`,
};
const userPool: CognitoUserPool = new CognitoUserPool(poolData);
let currentUser = userPool.getCurrentUser();
let secretCodeDevice: string;

const callbacks = (resolve: (data: any) => void, reject: (err: any) => void) => {
    return {
        onSuccess: function (res: any) {
            console.log('success', res);
            resolve(res);
        },
        onFailure: function (err: any) {
            console.log('failed', err);
            reject(err);
        },
        newPasswordRequired: (userAttributes: any) => {
            console.log('userAttributes', userAttributes);
            delete userAttributes.email_verified;
            delete userAttributes.email;
            publish('auth', AUTH_STATE.NEW_PASSWORD);
        },
        mfaSetup: (mfaType: ChallengeName) => {
            console.log(mfaType);
            currentUser?.associateSoftwareToken({
                associateSecretCode: (secretCode: string) => {
                    console.log('secretCode', secretCode);
                    secretCodeDevice = secretCode;
                    publish('auth', AUTH_STATE.MFA_SETUP);
                },
                onFailure: (err: any) => {
                    console.error(err);
                },
            });
        },
        totpRequired: (challengeName: ChallengeName, challengeParameters: any) => {
            console.log(challengeName, challengeParameters);
            publish('auth', AUTH_STATE.MFA_CODE);
        },
        mfaRequired: (codeDeliveryDetails: ChallengeName) => {
            console.log(codeDeliveryDetails);
            publish('auth', AUTH_STATE.MFA_CODE);
        },
    };
};

const getCognitoUser = (username: string) => {
    const userData = {
        Username: username,
        Pool: userPool,
    };
    return new CognitoUser(userData);
};

const getSecretCodeDevice = () => {
    return `otpauth://totp/${
        window.location.hostname
    }:${currentUser?.getUsername()}?secret=${secretCodeDevice}&issuer=${window.location.hostname}`;
};

const handleNewPassword = (newPassword: string) => {
    return new Promise((resolve, reject) => {
        const sessionUserAttributes = JSON.parse(localStorage.getItem('userAttributes') || '{}');
        console.log('complete new password', sessionUserAttributes, newPassword);
        currentUser?.completeNewPasswordChallenge(
            newPassword,
            sessionUserAttributes,
            callbacks(resolve, reject)
        );
    });
};

const authWithEmail = (username: string, password: string) => {
    return new Promise((resolve, reject) => {
        const authDetails = new AuthenticationDetails({ Username: username, Password: password });
        currentUser = getCognitoUser(username);
        currentUser.authenticateUser(authDetails, callbacks(resolve, reject));
    }).catch((err) => {
        throw err;
    });
};

const handleTotpRequired = (confirmationCode: string) => {
    return new Promise((resolve, reject) => {
        currentUser?.sendMFACode(
            confirmationCode,
            callbacks(resolve, reject),
            'SOFTWARE_TOKEN_MFA'
        );
    });
};

const handleMFARequired = (confirmationCode: string) => {
    return new Promise((resolve, reject) => {
        currentUser?.sendMFACode(confirmationCode, callbacks(resolve, reject), 'SMS_MFA');
    });
};

const handleChallenge = async (challengeName: string, confirmationCode: string) => {
    console.log('current type: ', challengeName);
    if (challengeName === 'SMS_MFA') {
        return handleMFARequired(confirmationCode);
    } else if (challengeName === 'SOFTWARE_TOKEN_MFA') {
        return handleTotpRequired(confirmationCode);
    } else {
        throw new Error('Unknown mfa type.');
    }
};

const associateDevice = (challengeAnswer: string) => {
    return new Promise(function (resolve, reject) {
        currentUser?.verifySoftwareToken(
            challengeAnswer,
            'My TOTP device',
            callbacks(resolve, reject)
        );
    });
};

const changePassword = async (oldPassword: string, newPassword: string, mfaCode: string) => {
    return new Promise(function (resolve, reject) {
        const authDetails = new AuthenticationDetails({
            Username: currentUser?.getUsername() || '',
            Password: oldPassword,
        });
        currentUser?.authenticateUser(authDetails, {
            onSuccess: function (result) {
                resolve(result);
            },
            onFailure: function (err: any) {
                reject(err);
            },
            totpRequired: (challengeName: ChallengeName, challengeParameters: any) => {
                console.log(challengeName, challengeParameters);
                handleChallenge(challengeName, mfaCode)
                    .then(() =>
                        currentUser?.changePassword(
                            oldPassword,
                            newPassword,
                            function (err: any, res: any) {
                                if (err) {
                                    reject(err);
                                } else {
                                    resolve(res);
                                }
                            }
                        )
                    )
                    .catch((error) => reject(error));
            },
        });
    });
};

const isAuthorized = () => {
    const username = localStorage.getItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_CLIENT_ID}.LastAuthUser`
    );
    const idToken = localStorage.getItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_CLIENT_ID}.${username}.idToken`
    );
    if (idToken) {
        const jwtDecoded = jwtDecode(idToken);
        return Date.now() <= (jwtDecoded as any)?.exp * 1000;
    }
    return false;
};

const signOut = () => {
    if (currentUser) {
        currentUser.signOut(() => {
            localStorage.setItem(`sessionId`, 'undefined');
            localStorage.setItem(`invalidSession`, 'true');
        });
    }
};

export const useAuth = {
    authWithEmail,
    changePassword,
    isAuthorized,
    signOut,
    getSecretCodeDevice,
    associateDevice,
    handleNewPassword,
    handleChallenge,
};
