import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as FullStory from '@fullstory/browser';

FullStory.init({
    orgId: process.env.REACT_APP_FULLSTORY_ORG_ID || '',
    devMode: process.env.REACT_APP_FULLSTORY_DEV_MODE === 'true' || false,
    host: process.env.REACT_APP_FULLSTORY_HOST || 'fullstory.com',
    script: process.env.REACT_APP_FULLSTORY_SCRIPT || 'edge.eu1.fullstory.com/s/fs.js',
    namespace: process.env.REACT_APP_FULLSTORY_NAMESPACE || 'FS',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
